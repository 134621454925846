* {
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.col-md-4 {
  margin-top: 1rem;
}

.card {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: .3s;

  &:hover {
    transform: scale(1.04);
  }
}

.body-container, .nav-container, .details-container {
  width: 100%;
  padding: 0rem 2rem;
  margin: 0 auto;
}

.nav-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-container {
  margin: 1rem .25rem;
  flex-wrap: nowrap;
  
  p {
    margin: 0;

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .about-section {
    margin: 0 1.5rem;
    overflow-wrap: break-word;
  }
}

.details-container {
  margin-top: 1rem;
}

// navbar hamburger animation
.navbar-toggler {
  span {
    display: block;
    background-color: #909090;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
  
    &:nth-child(1) {
      transform: translate(0%, 0%) rotate(0deg);
      margin-top: 0.3em;
    }
  
    &:nth-child(2) {
      opacity: 1;
    }
    
    &:nth-child(3) {
      transform: translate(0%, 0%) rotate(0deg);
    }
  }

  &:not(.collapsed) span{
    &:nth-child(1) {
      transform: translate(15%, -33%) rotate(45deg);
    }
    
    &:nth-child(2) {
      opacity: 0;
    }
    
    &:nth-child(3) {
      transform: translate(15%, 33%) rotate(-45deg);
    }
  }
}

@media screen and (max-width: 768px) {
  .about-section {
    min-width: 310px;
  }

  .about-image img {
    width: 40vw !important;
  }

  .card:hover {
    transform: none;
  }
}

@media screen and (max-width: 576px) {
  .about-container {
    flex-wrap: wrap;

    .about-section {
      margin: 1rem 0;
      min-width: 315px;
    }

    .about-image img {
      width: 60vw !important;
    }
  }
}

@media screen and (min-width: 992px) {
  .body-container, .nav-container, .details-container {
    max-width: 960px;
  }

  .nav-container {
    padding: 0 2.75rem;
  }
}

@media screen and (min-width: 1200px) {
  .body-container, .nav-container, .details-container {
    max-width: 1140px;
  }
}
